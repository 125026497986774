import React from 'react'

const TermsAndConditions = () => {
  return (
    <section>
      <h1 className='center-text'>Terms & Conditions</h1>
      <h2>OVERVIEW</h2>
      <p>CNU (ca-nu) allows users (“You,” “Yourself,” or “Your,” if possessive) a way to connect to other users whom may provide a service (“Service Provider,” “Connection,” “Connections”). It also will allow users to be located for potential services they might provide to other users. Reviews and ratings on a variety of services can be read and posted to provide information about the experiences the user had with these Service Providers.</p>
      <h2>REGISTRATION INFORMATION</h2>
      <p>As a condition of Your use of the Service, You agree to: (a) provide CNU with true, accurate, current and complete information as prompted by the CNU’s registration forms, when registering for or using the Service; and (b) update and maintain the truthfulness, accuracy and completeness of such information.</p>
      <h2>PRIVACY POLICY</h2>
      <p>CNU has established a Privacy Policy to explain to You, and other users, how Your personal information is collected and used. This Privacy Policy is located in Your settings on the Mobile Application.</p>
      <h2>ACCOUNT SECURITY</h2>
      <p>You will choose your user ID and a password when You register. Your user ID and password may only be used by You and the members of Your household (meaning anyone who currently shares with You the address You registered with CNU). You are solely responsible for maintaining and protecting the confidentiality of Your user ID and password, and are fully responsible for all activities that occur under Your user ID and password.</p>
      <h2>COMMUNICATION</h2>
      <p>Communication can be can be established through the app (standard sms rates of cellular carrier apply) to connect to other users within the network. Messages will be stored in users inbox in order of most recent active conversation. Deleted messages will be removed from inbox and cannot be restored. You may also call Connection (standard calling rates of cellular carrier apply) if permission to receive calls is established in Connections settings. If You wish not be called by Connections, toggle switch in settings stating “only contact via SMS” must be set to ON.</p>
      <h2>SUBMISSIONS OF REVIEWS</h2>
      <p>In order for you to submit your own reviews and ratings you acknowledge and agree that:</p>
      <ol>
        <li>All of Your reviews and ratings will either be based upon your actual first-hand experiences with the Service Providers You are reviewing</li>
        <li>All of Your reviews and ratings of the Service Providers that You are rating will be accurate, truthful and complete in all respects;</li>
        <li>You do not work for, own any interest in, or serve on the board of directors of, any of the Service Providers for which You submit reviews and ratings;</li>
        <li>You do not work for, own any interest in or serve on the board of directors of any competitors of the Service Providers for which You submit reviews and ratings;</li>
        <li>Your name and review information will be made available to the Service Providers on which You review; and</li>
        <li>CNU may redact, delete, or reject Your reviews if they do not conform with CNU’s publication criteria, which may change from time to time at CNU’s sole discretion.</li>
      </ol>
      <h2>CONFLICT RESOLUTION PROCESS</h2>
      <p>If you have a dispute with a Service Provider you may submit a report through the profile. CNU will review report and investigate into the potential resolution. Documentation may be requested from both parties during investigation to understand full scope of the reported situation. CNU can, if needed, remove and block user from CNU network if situation warrants this action.</p>
      <h2>SERVICE PROVIDERS</h2>
      <p>CNU does not endorse and is not responsible or liable for any Content, Service Provider Content, Promotions, data, advertising, products, goods or services available or unavailable from, or through, any Service Providers. You agree that should You use or rely on such Content, Service Provider Content, Promotions, data, advertisement, products, goods or services, available or unavailable from, or through any Service Provider, CNU is not responsible or liable, indirectly or directly, for any damage or loss caused or alleged to be caused by or in connection with such use or reliance. Your dealings with, or participation in promotions of any Service Provider, and any other terms, conditions, representations, are between You and such Service Provider exclusively and do not involve CNU. You should make whatever investigation or other resources that You deem necessary or appropriate before hiring or engaging Service Providers. You agree that CNU is not responsible for the accessibility or unavailability of any Service Provider or for Your interactions and dealings with them, waive the right to bring or assert any claim against CNU relating to any interactions or dealings with any Service Provider, and release CNU from any and all liability for or relating to any interactions or dealings with Service Providers. In addition, You agree that CNU may exclude Service Providers from displaying in search results on the CNU’s Network for failing

to meet particular CNU standards regarding Service Provider conduct and performance.</p>
      <h2>CNU IS NOT RESPONSIBLE FOR ANY ILLEGAL ACTIVITY THAT OCCURS PRIOR, DURING, OR AFTER CONNECTION BETWEEN USER AND SERVICE PROVIDER IS ESTABLISHED. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT CNU DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS. CNU MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS. CNU RESERVES THE RIGHT TO CONDUCT - AND YOU AGREE THAT CNU MAY CONDUCT - ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS.</h2>
      <h2>YOUR CONDUCT</h2>
      <p>In connection with Your use of the Service, You represent and warrant that You</p>
      <ol>
        <li>are above the age of eighteen (18)</li>
        <li>will abide by the letter and spirit of the terms and conditions of this Agreement and all applicable local, state, national or international laws;</li>
        <li>will not submit any reviews that may be considered by CNU to be unlawful, harassing, libelous, abusive, threatening, obscene, profane, hateful, offensive, harmful, vulgar, distasteful, defamatory, invasive of another person’s privacy or proprietary rights, or racially, ethnically or otherwise objectionable;</li>
        <li>will submit thorough and thoughtful reviews of the Service Providers You review (for example, submitting a review

describing a service as “He/She is great.” without additional commentary is not a thorough and thoughtful review);</li>
        <li>will not submit reviews that comment on other users or the reviews of other users;</li>
        <li>will not impersonate, or attempt to impersonate, any other person, falsify contact information, misrepresent a relationship with any person or entity, including misrepresenting a relationship with CNU, or otherwise attempt to mislead others as to the identity of the sender or the origin of a review or rating;</li>
        <li>will not submit reviews that are encrypted or that contain viruses, Trojan horses, worms, time bombs, spiders, cancelbots, or other computer programming routines that are intended to damage, interfere with, disrupt, impair, disable or otherwise overburden our Service;</li>
        <li>will not access, download or copy any information contained on our Mobile Application through artificial means (including but not limited to spiders, hacking devices, computer programs, bots or other such means);</li>
        <li>will not post non-local or otherwise irrelevant Content, repeatedly post the same or similar Content or otherwise impose an unreasonable or disproportionately large load on our infrastructure;</li>
        <li>will not take any action that would undermine the review and rating process under the Service;</li>
        <li>will not attempt to gain unauthorized access to the Service, other user accounts, or other computer systems or networks connected to the Service;</li>
        <li>will not use the Service in any manner that infringes, misappropriates or violates any third party’s rights, including, but not limited to, transmitting any material that may infringe, misappropriate or violate a third party’s rights of publicity, contractual rights, fiduciary rights or intellectual property rights;</li>
        <li>will not use the Service in any way that could interfere with the rights of CNU or the rights of other users of the Service;</li>
        <li>have sufficient rights in and to all Content that You provide, transmit or otherwise convey to CNU in connection with the Service;</li>
        <li>agree not to re-sell or assign Your rights or obligations under this Agreement;</li>
        <li>will not reproduce, duplicate, copy, sell, re-sell or exploit any Content;</li>
        <li>will not access any Content for any commercial, educational or other purposes not related to Your personal decisions, the express written consent of CNU, which consent may be withheld by CNU in our discretion;</li>
        <li>grant us an irrevocable, perpetual, non-exclusive, fully paid, worldwide license to use, copy, perform, display, reproduce, adapt, modify and distribute the Content and to prepare derivative works of, or to incorporate such Content into other works, and to grant and to authorize sublicenses of the foregoing;</li>
        <li>agree not to create an account or use CNU if Your account previously has been terminated by CNU or if You previously have been banned from using the services; and</li>
        <li>agree not to: (i) register for more than one account or register for an account on behalf of an individual other than Yourself; (ii) impersonate any person or entity, including, but not limited to, CNU personnel, or falsely state or otherwise misrepresent Your affiliation with a person or entity; or (iii) advocate, encourage or assist any third party in doing any of the foregoing activities in this subsection.</li>
      </ol>
      <p>The reviews and ratings that You provide do not reflect the views of CNU, its officers, managers, owners, employees, agents, designees or other users. In addition, CNU retains

the right, in its sole discretion, to determine whether or not Your use of the Service is consistent with the terms and conditions of this Agreement. CNU may suspend, restrict or terminate Your use of the Service and to refuse any future use of all or portions of the Service if Your use breaches or fails to comply with any of the terms and conditions of this Agreement. Additionally, CNU may seek any and all other remedies available to it, including: (a) seeking injunctive relief with any court of competent jurisdiction to enjoin any breach or failure to comply with any of the terms and conditions of this Agreement; and/or (b) if damages are ascertainable, seeking damages relating to any breach or failure to comply with any of the terms and conditions of this Agreement.</p>
      <h2>CHILDREN AND UNDERAGE USERS</h2>
      <p>CNU does not intend the Site and Services to be used by individuals under the age of majority within the jurisdiction within which they reside without the supervision of a parent or guardian of majority age. CNU relies upon such parents or guardians to determine if the Site and Services is appropriate for the viewing, access, or participation by such individuals under the age of majority. If an individual under the age of majority uses the Site and Services, such individual may only use the Site and Services under the supervision of a parent or guardian of majority age</p>
      <h2>CNU IS NOT RESPONSIBLE FOR ANY USER WHOM PARTICIPATES IN OUR SERVICES AS IT IS THE SOLE RESPONSIBILTY OF THE USERS PARENT OR GUARDIAN TO MONITOR THE SERVICES USED BY SAID USER.</h2>
      <h2>TERM AND TERMINATION</h2>
      <p>The term of this Agreement (“Term”) will be in effect and continue so long as You are an active user. In other words, the Term shall continue until termination by either party in accordance with the terms of this Agreement.

CNU may, for any reason in its sole discretion, immediately terminate this Agreement, Your account, and Your access to the Service. Termination of Your account will include removal of Your access to all offerings of the Service, deletion of Your password, deletion of all related information and files, may include the deletion of the Content associated with Your account (or any part thereof), and barring Your further use of the Service.</p>
      <h2>MODIFICATION OF TERMS AND CONDITIONS</h2>
      <p>CNU will have the right to modify and restate the terms and conditions of this Agreement, and such modification(s) will be effective immediately upon being posted on our Mobile Application and Website (www.creativesnearyou.com). You will receive notice if modifications to the Agreement are made. CNU will make note of the date of the last update to the Agreement on the first page of this Agreement. You are responsible for reviewing these terms and conditions regularly. Your continued use of the Service after such modifications will be deemed to be Your conclusive acceptance of all modifications to this Agreement. If You are dissatisfied as a result of such modification(s), Your only recourse is to immediately discontinue use of the Service</p>
      <h2>MODIFICATION, LIMITATION AND DISCONTINUANCE OF SERVICE</h2>
      <p>CNU reserves the right at any time to limit access to, modify, change or discontinue the Service with or without notice to You and we shall not be liable to You for any such modification, suspension or discontinuance of the Service. You agree that CNU will not be liable to You or to any third

party for any such limitation, modification, change, suspension or discontinuance of the Service. You agree that CNU may establish general practices, policies and limits, which may or may not be published, concerning the use of the Service, including without limitation, the time that reviews and ratings will be retained, the maximum number of reviews and ratings that may be sent from an account, the length of reviews and ratings sent, and the maximum number of times and the maximum duration for which You may access the Service in a given period of time. You agree that CNU has no responsibility or liability for the deletion or failure to store any reviews, ratings and other communications maintained or transmitted by or through the Service. You agree that CNU has the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>
      <h2>DELAYS</h2>
      <p>The Service may be subject to limitations, delays and other problems inherent in the use of the Internet and electronic communications. CNU is not responsible for any delays, failures or other damage resulting from such problems</p>
      <h2>USER FEEDBACK</h2>
      <p>CNU appreciates hearing from You, as well as our other users, and welcomes Your comments regarding our Service. Please be advised, however, that our policy does not permit us to accept or consider creative ideas, suggestions, or materials other than those which we have specifically requested. Although we do value Your feedback on our Service, please be specific in Your comments regarding our services and do not submit creative ideas, suggestions or materials. If, despite our request, You send us creative suggestions, ideas, drawings, concepts or other information (collectively, the “Submissions”), such Submissions will be the property of CNU. In addition, none of the Submissions

will be subject to any obligations of confidentiality and CNU will not be liable for any future use or disclosure of such Submissions.</p>
      <h2>COPYRIGHT MATERIALS</h2>
      <p>Aside from user-submitted Content and Service Provider Content, all other materials and other information on the Mobile Application and Website, including, but not limited to, all text, graphics, logos, icons, images, audio clips, downloads, data compilations and software (collectively, the “Copyright Materials”) are the exclusive property of CNU and/or its licensors and are protected by all United States and international copyright laws.</p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT CNU WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF CNU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) (COLLECTIVELY, “DAMAGES”), RESULTING FROM: (A) THE USE OR INABILITY TO USE THE SERVICE; (B) THE COST OF ANY GOODS AND/OR SERVICES PURCHASED OR OBTAINED AS A RESULT OF THE USE OF THE SERVICE; (C) DISCLOSURE OF, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR INFORMATION OR CONTENT; (D) CONTENT YOU SUBMIT, RECEIVE, ACCESS, TRANSMIT OR OTHERWISE CONVEY THROUGH THE SERVICE; (E) STATEMENTS OR CONDUCT OF ANY SERVICE PROVIDERS OR OTHER THIRD PARTY THROUGH THE SERVICE; (F) ANY OTHER MATTER RELATING TO THE SERVICE; (G) ANY BREACH OF THIS AGREEMENT BY CNU OR THE FAILURE

SERVICE UNDER THIS AGREEMENT OR (H) ANY OTHER DEALINGS OR INTERACTIONS YOU HAVE WITH ANY SERVICE PROVIDERS (OR ANY OF THEIR REPRESENTATIVES OR AGENTS). THESE LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.

In some jurisdictions, limitations of liability are not permitted. In such jurisdictions, some of the foregoing limitations may not apply to You.</p>
      <h2>INDEMNIFICATION</h2>
      <p>You agree to indemnify, defend and hold harmless CNU, its officers, managers, owners, employees, agents, designees, users, successors, assigns, service providers and suppliers from and against all losses, liabilities, expenses, damages, claims, demands and costs, including reasonable attorneys’ fees and court costs due to or arising from: (a) any violation of this Agreement by You; (b) the inaccurate or untruthful Content or other information provided by You to CNU or that You submit, transmit or otherwise make available through the Service; or (c) any intentional or willful violation of any rights of another or harm You may have caused to another. CNU will have sole control of the defense of any such damage or claim.</p>
      <h2>BREACH OF AGREEMENT AND LIQUIDATED DAMAGES</h2>
      <p>You understand and agree that, because damages are often difficult to calculate, if it becomes necessary for CNU to pursue legal action to enforce the terms and conditions of this Agreement, You will be liable to pay us the following amounts as liquidated damages, which You accept as reasonable estimates of CNU damages for the specified breaches of this Agreement:</p>
      <ol>
        <li>If You post Content in violation of this Agreement, You agree to promptly pay CNU One Thousand Dollars

($1,000) for each item of Content posted in violation of this Agreement. We may (but shall not be required to) to issue You a warning before assessing damages.</li>
        <li>If You display, copy, duplicate, reproduce, sell, re-sell or exploit for any purpose any Content in violation of this Agreement, You agree to pay One Thousand Dollars ($1,000) for each item of Content displayed, copied, duplicated, reproduced, sold, re-sold or exploited in violation of this Agreement</li>
        <li>If You use computer programming routines that are intended to aggregate records or reviews from the Service or otherwise damage, interfere with, disrupt, impair, disable or otherwise overburden our Mobile Application or Website, You agree to pay One Hundred Dollars ($100) for each review or record that is aggregated, disrupted, damaged or otherwise affected by You.</li>
        <li>Except as set forth in the foregoing subsections (a) through (c), inclusive, You agree to pay the actual damages suffered by CNU, including, but not limited to attorneys’ fees and court costs, to the extent such actual damages can be reasonably calculated. Notwithstanding any other provision of this Agreement, we reserve the right to seek the remedy of specific performance of any term contained herein, or a preliminary or permanent injunction against the breach of any such term or in aid of the exercise of any power granted in this Agreement, or any combination thereof.</li>
      </ol>
      <h2>ENTIRE AGREEMENT</h2>
      <p>This Agreement governs Your use of the Service and constitutes the entire agreement between You and CNU. It supersedes any prior or contemporaneous negotiations, discussions or agreements, whether written or oral, between You and CNU regarding the subject matter contained in this

Agreement. Additional terms and conditions may exist between You and third parties, including but not limited to, Service Providers and others. You represent and warrant that those third party agreements do not interfere with Your obligations and duties to CNU under this Agreement.</p>
      <h2>GOVERNING LAW</h2>
      <p>This Agreement and the relationship between You and CNU will be governed by the laws of the State of New York, notwithstanding the choice of law provisions of the venue where any action is brought, where the violation occurred, where You may be located or any other jurisdiction. You agree and consent to the exclusive jurisdiction of the state or federal courts located in Queens County, New York and waive any defense of lack of personal jurisdiction or improper venue or forum non conveniens to a claim brought in such court, except that CNU may elect, in its sole discretion, to litigate the action in the county or state where any breach by You occurred or where You can be found. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out or related to Your use of the Service or this Agreement shall be filed within one (1) year after such claim or cause of action arose or will forever be barred.</p>
      <h2>CNU TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK. CNU DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY CONDUCT OF YOU OR ANY OTHER USER, ON OR OFF THE SERVICE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MUST NOT USE THE

SERVICE. BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS OF THIS AGREEMENT AND YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.</h2>
    </section>
  )
}

export default TermsAndConditions
