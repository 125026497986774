import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

// Sections
import TermsAndConditions from '../components/legal-pages/terms-and-conditions'

const Story = () => {
  return (
    <Layout>
      <SEO title="Creatives Near You | Our Story" />
      <TermsAndConditions />
    </Layout>
  )
}

export default Story